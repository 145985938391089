import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import React, { memo, useEffect } from 'react';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import sec3concretimg from "../../assets/images/sec-3-concret-img.png"
import concretevideo from "../../assets/video/concrete.mp4"
import steps1 from "../../assets/images/steps1.png"
import steps2 from "../../assets/images/steps2.png"
import steps3 from "../../assets/images/steps3.png"
import steps4 from "../../assets/images/steps4.png"
import steps5 from "../../assets/images/steps5.png"
import stepspathbefore from "../../assets/images/steps-path-before.svg"
import stencil from "../../assets/images/stencil.png"
import stencilicon1 from "../../assets/images/stencil-icon1.png"
import stencilicon2 from "../../assets/images/stencil-icon2.png"
import stencilicon3 from "../../assets/images/stencil-icon3.png"
import stencilicon4 from "../../assets/images/stencil-icon4.png"
import stencilicon5 from "../../assets/images/stencil-icon5.png"
import stencilicon6 from "../../assets/images/stencil-icon6.png"
import pattern1 from "../../assets/images/pattern1.png"
import pattern5 from "../../assets/images/pattern5.png"
import pattern3 from "../../assets/images/pattern3.png"
import pattern2 from "../../assets/images/pattern2.png"
import gr1 from "../../assets/images/gr-1.png"
import gr7 from "../../assets/images/gr-7.png"
import gr5 from "../../assets/images/gr-5.png"
import gr4 from "../../assets/images/gr-4.png"
import stampedoverlay from "../../assets/images/stamped-overlay.png"
import STAMPED1 from "../../assets/images/STAMPED1.png"

import materialimg1 from "../../assets/images/material-img1.png"
import materialimg2 from "../../assets/images/material-img2.png"
import materialimg3 from "../../assets/images/material-img3.png"
import materialimg4 from "../../assets/images/material-img4.png"
import materialimg5 from "../../assets/images/material-img5.png"
import timeeffe from "../../assets/images/timeeffe.png"

import proces from "../../assets/images/proces.png"
import proces1 from "../../assets/images/proces1.png"
import proces4 from "../../assets/images/proces4.png"
import proces5 from "../../assets/images/proces5.png"

import Loader from '../../Components/Loader';

function ConcretOverlay() {



    useEffect(() => {
        AOS.init({
          // AOS configuration options
        });
    
        // Refresh AOS when component mounts
        AOS.refresh();
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const handleScroll = () => {
        // Refresh AOS on scroll to trigger animations
        AOS.refresh();
      };

      
  return (
    
    <div>
   <Header/>
 

   <div class="side-space">

<section class="sec-1 concrete-sec-1">
    <div class="bg-video-wrap">
    <video src={concretevideo} loop muted autoPlay>
            </video>
        <div class="overlay">
        </div>

        <div class="banner-content">
            <h1 class="">CONCRETE OVERLAYS</h1>




        </div>


    </div>

</section>


<div class="side-padding over-pg">




    <section class="sec-3-concret pt-5">
        <div class="inner-title">
            <h1 data-aos="fade-up" data-aos-duration="1800"><span></span> CONCRETE Overlays</h1>
            <p data-aos="fade-up" data-aos-duration="2000">Concrete overlay, also known as concrete resurfacing or
                concrete topping, is a process of applying a
                new layer of concrete over an existing concrete surface. It is commonly used to restore or enhance
                the appearance of worn-out, damaged, or outdated concrete surfaces without the need for complete
                removal and replacement.
                The overlay material can be a thin layer of polymer-modified cementitious material or a specialized
                concrete mix designed for resurfacing purposes. It is typically applied in thicknesses ranging from
                (3-50 mm). The overlay can be coloured, stained, or textured to achieve the desired aesthetic effect
            </p>

        </div>
        <div class="grind">
            <img src={sec3concretimg}/>
        </div>
        <p class="extra-para pb-5">STEPS OF APPLYING A CONCRETE OVERLAY</p>
        <div class="row" style={{position:"relative"}}>
            <span class="path-steps"><img src={stepspathbefore}/></span>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={steps1} />
                    <strong>1</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">SURFACE PREPARATION</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">The existing concrete surface is thoroughly
                        cleaned, removing any dirt, grease, or loose
                        material. Any cracks, spalls, or other damage may need to be repaired before the overlay is
                        applied.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={steps2} />
                    <strong>2</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">BONDING AGENT</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">A bonding agent or primer is applied to the
                        prepared surface to enhance the adhesion between
                        the existing concrete and the overlay material. This helps ensure a strong bond between the
                        two layers.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 table-none">
                <div class="step-concret">
                    <img src={steps3} />
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Mixing and Application</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">The overlay material is mixed according to the
                        manufacturer's instructions, taking into
                        account the desired thickness and any specific requirements. It is then applied to the
                        prepared surface using trowels, squeegees, or other suitable tools. The material is spread
                        evenly and worked into the existing concrete.</p>
                </div>
            </div>
        </div>

        <div class="row sec3row3" style={ {position:"relative", justifyContent: "space-around", marginTop: "40px"}}>
            <span class="path-steps"><img src={stepspathbefore}/></span>
            <div class="col-lg-4 col-md-6 destop-none">
                <div class="step-concret">
                    <img src={steps3} />
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Mixing and Application</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">The overlay material is mixed according to the
                        manufacturer's instructions, taking into
                        account the desired thickness and any specific requirements. It is then applied to the
                        prepared surface using trowels, squeegees, or other suitable tools. The material is spread
                        evenly and worked into the existing concrete.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={steps4} />
                    <strong>4</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Finishing and Texturing</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Once the overlay is applied, it can be finished and textured to achieve the desired appearance. This can include techniques such as grinding and polishing coloured/plain concrete, exposed aggregate, or scoring to create patterns or textures.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="step-concret">
                    <img src={steps5} />
                    <strong>5</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Curing and Sealing</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">The overlay needs time to cure and harden,
                        usually for a few days. During this time, it
                        should be protected from excessive moisture, temperature extremes, and heavy traffic. Once
                        fully cured, a sealer is typically applied to enhance durability, protect against stains,
                        and provide a finished look. <br/>
                        Concrete overlays offer several advantages. They can significantly improve the appearance of
                        old or damaged concrete surfaces, providing a fresh and attractive look. They can also help
                        address minor surface imperfections, such as cracks or discoloration. Additionally, overlays
                        can be more cost-effective and time-efficient compared to complete removal and replacement
                        of concrete.
                    </p>
                </div>
            </div>

        </div>

        <div class="single-para" data-aos="fade-up" data-aos-duration="1800">
            <p>However, it's important to note that concrete overlays are suitable for surfaces with minor damage or
                wear. If the underlying concrete has significant structural issues, it may require more extensive
                repairs or replacement. <br/>
                It's recommended to consult with us so we assess the condition of the existing concrete and provide
                guidance on the most appropriate overlay system for your specific needs.

            </p>
        </div>
    </section>

    <section class="sec-2-concret s-4">
        <div class="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span> STENCIL PATTERN OVERLAY</h1>
            <p>Stencil pattern overlay is a decorative concrete technique that involves creating the appearance of a
                patterned surface by using stencils. It is commonly used to mimic the look of various materials such
                as brick, stone, tile, or slate, without the need for those specific materials. A stencil overlay is
                often used as an affordable solution to revive old worn-out concrete.
            </p>

        </div>
        <div class="grind">
            <img src={stencil} />
            <p class="extra-para pt-5">The application process for stencil pattern concrete typically <br/>
                involves the following steps</p>
        </div>

        <div class="row" style={{position:"relative", marginBottom:"50px"}}>
            <span class="path-steps"><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={steps1} />
                    <strong>1</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">SURFACE PREPARATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">The existing concrete surface is thoroughly cleaned and prepared. This may involve pressure washing, repairing any cracks or damages, and ensuring a smooth and even surface.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon2} />
                    <strong>2</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">BASE COAT
                        APPLICATION</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">A base coat of
                        coloured concrete is applied to the prepared surface. This base coat serves as the
                        background colour for the pattern and provides a uniform appearance..</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 table-none">
                <div class="step-concret">
                    <img src={stencilicon3} />
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">STENCIL PLACEMENT
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Stencils are
                        carefully laid out and positioned on top of the base coat. These stencils have pre-cut
                        patterns or designs, allowing for precise replication of the desired pattern.</p>
                </div>
            </div>
        </div>
 {/* tablet */}
            <div className='destop-none'>
        <div class="row " style={{position:"relative", marginTop:"40px;" }}>
            <span class="path-steps"><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon3} />
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">STENCIL PLACEMENT
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Stencils are
                        carefully laid out and positioned on top of the base coat. These stencils have pre-cut
                        patterns or designs, allowing for precise replication of the desired pattern.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon4} />
                    <strong>4</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">COLOUR APPLICATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Different coloured
                        concrete or colour hardeners are applied to the stencils using various techniques such as
                        spraying, rolling, or trowelling. This creates the desired pattern and adds depth and
                        texture to the surface.</p>
                </div>
            </div>
            
        </div>
        </div>
 {/* tablet end*/}
        <div class="row" style={{position:"relative", marginTop:"40px;" }}>
            <span class="path-steps"><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6 table-none">
                <div class="step-concret">
                    <img src={stencilicon4} />
                    <strong>4</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">COLOUR APPLICATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Different coloured
                        concrete or colour hardeners are applied to the stencils using various techniques such as
                        spraying, rolling, or trowelling. This creates the desired pattern and adds depth and
                        texture to the surface.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon5}/>
                    <strong>5</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">STENCIL REMOVAL
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Once the colour
                        application is complete, the stencils are carefully removed, revealing the patterned design
                        on the concrete surface.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon6} />
                    <strong>6</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">SEALING</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">To protect the
                        concrete and enhance its appearance, a high-quality concrete sealer is applied. The sealer
                        also helps to improve the durability and longevity of the stencil pattern concrete.</p>
                </div>
            </div>
        </div>
        <p class="extra-para pt-5 pb-5">Choosing a professional for stencil pattern concrete installation is <br/>
            recommended for several reasons</p>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={pattern1}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Expertise and Experience</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professionals who specialize in decorative
                        concrete have the necessary knowledge, skills, and experience to ensure a high-quality
                        installation. They understand the intricacies of the process and can achieve better results
                        compared to inexperienced individuals.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={pattern2}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Proper Equipment and Materials</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professionals have access to specialized
                        equipment, tools, and materials required for stencil pattern concrete installation. This
                        ensures that the job is done efficiently and with the right techniques and products..</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={pattern3}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Design and Customization</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professionals can offer a wide range of stencil
                        patterns and design options to suit your preferences. They can provide guidance on selecting
                        the most suitable pattern for your project and help you achieve the desired aesthetic
                        result.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr1}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Quality and Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professional installers are familiar with the
                        best practices and techniques to ensure a durable and long-lasting stencil pattern concrete
                        surface. They use high-quality materials and follow proper curing and sealing processes to
                        maximize the lifespan of the installation.</p>
                </div>
            </div>




            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={pattern5} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Time and Cost Efficiency</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Hiring professionals can save you time and effort
                        as they are equipped to complete the job efficiently and within a reasonable timeframe. They
                        can also help you avoid costly mistakes that may arise from DIY attempts or inexperienced
                        installations..</p>
                </div>
            </div>



            <div class="single-para" data-aos="fade-up" data-aos-duration="1800">
                <p>Overall, choosing a professional for stencil pattern concrete installation can give you peace of
                    mind, ensure a high-quality result, and enhance the visual appeal and longevity of your concrete
                    surface.
                </p>
            </div>
        </div>
    </section>


    <section class="sec-2-concret s-4">
        <div class="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span> STAMPED OVERLAY</h1>
            <p>Concrete stamp overlay is a decorative flooring option that involves applying a thin layer of
                specialized concrete mixture onto an existing concrete surface and then stamping it with various
                patterns and textures to resemble other materials like stone, <br/> brick, or tile.
            </p>
            <p>Here are the benefits, the process of application, what it's made of, and why it's advisable to
                choose a professional for its <br/> application</p>

        </div>
        <div class="grind">
            <img src={stampedoverlay} />
            <p class="extra-para pt-5">Benefits of Concrete Stamp Overlay</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={STAMPED1}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">Aesthetic Appeal
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Concrete stamp
                        overlay provides a visually appealing and customizable flooring solution, allowing you to
                        achieve the look of expensive materials at a fraction of the cost.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr1}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">It creates a strong
                        and durable surface that can withstand heavy foot traffic and is resistant to stains,
                        abrasion, and UV damage.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr7}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">Versatility</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">The stamping process
                        offers a wide range of design options, including different patterns, textures, colours, and
                        finishes, enabling you to create unique and personalized surfaces.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr5}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">Cost-Effective</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Compared to
                        installing new flooring materials, concrete stamp overlay offers significant cost savings
                        while achieving similar visual effects.</p>
                </div>
            </div>




            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr4}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">Quick Installation
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">The overlay process is
                        relatively fast, requiring less time and effort compared to completely replacing the
                        existing concrete slab.</p>
                </div>
            </div>



        </div>
        <div class="grind">
            <img src={proces} />
            <p class="extra-para pt-5">PROCESS OF APPLICATION</p>
        </div>



        <div class="row"  style={{position:"relative" }}>
            <span class="path-steps"><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={steps1 }/>
                    <strong>1</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">SURFACE PREPARATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">The existing
                        concrete surface is thoroughly cleaned, repaired (if necessary), and prepared by removing
                        any dirt, grease, or loose particles.

                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={proces1} />
                    <strong>2</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">PRIMER APPLICATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">A bonding primer is
                        applied to ensure proper adhesion between the existing concrete and the overlay material.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 table-none">
                <div class="step-concret">
                    <img src={stencilicon2}/>
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">OVERLAY
                        INSTALLATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">The specialized
                        concrete mixture is prepared according to the manufacturer's instructions and applied in
                        layers using trowels and specialized tools.</p>
                </div>
            </div>
        </div>

        
 {/* tablet */}
 <div className='destop-none'>
        <div class="row " style={{position:"relative", marginTop:"40px;" }}>
            <span class="path-steps "><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={stencilicon2}/>
                    <strong>3</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">OVERLAY
                        INSTALLATION
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">The specialized
                        concrete mixture is prepared according to the manufacturer's instructions and applied in
                        layers using trowels and specialized tools.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="step-concret">
                    <img src={proces4} />
                    <strong>4</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init">STAMPING</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">Once the overlay is applied,
                        stamping mats or texturing tools are pressed into the wet overlay to create the desired
                        patterns and textures.</p>
                </div>
            </div>
            
        </div>
        </div>
 {/* tablet end*/}


        <div class="row" style={ {position:"relative", justifyContent: "space-around", marginTop: "40px"}}>
            <span class="path-steps table-none"><img src={stepspathbefore} /></span>
            <div class="col-lg-4 col-md-6 table-none">
                <div class="step-concret">
                    <img src={proces4} />
                    <strong>4</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init">STAMPING</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">Once the overlay is applied,
                        stamping mats or texturing tools are pressed into the wet overlay to create the desired
                        patterns and textures.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="step-concret">
                    <img src={proces5} />
                    <strong>5</strong>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init">COLOURING AND SEALING</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">Pigments or stains can be added
                        to the overlay to achieve the desired colour, followed by the application of a protective
                        sealer to enhance durability and resist staining.
                    </p>
                </div>
            </div>

        </div>
        <p class="extra-para pt-5 ">MATERIAL USED</p>
        <p class="pb-5"  style={ {textAlign:"center"}}>The materials used in concrete stamp overlay typically include
        </p>


        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={materialimg1}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">POLYMER-MODIFIED OVERLAY MIX
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">This is a
                        specialized concrete mixture that contains polymers to enhance its flexibility, adhesion,
                        and durability.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={materialimg2} data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">BONDING PRIMER</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">It promotes adhesion
                        between the existing concrete and the overlay material.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={materialimg3}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">STAMPING MATS OR TEXTURING TOOLS</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">These tools come in
                        various patterns and textures to create the desired surface appearance.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={materialimg4}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">COLOUR PIGMENTS OR STAINS</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">These are used to
                        add colour to the overlay and achieve the desired aesthetic effect.</p>
                </div>
            </div>




            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={materialimg5}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">PROTECTIVE SEALER
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">It is applied to the stamped
                        overlay to enhance its durability and protect against stains and damage.</p>
                </div>
            </div>
            <p class="extra-para pt-5 pb-5 ">Choosing a Professional for Application: While concrete stamp <br/>
                overlay can be a DIY project for those with experience in concrete<br/>
                work, it is generally recommended to hire a professional for the following reasons</p>


        </div>


        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                <img src={pattern1}  data-aos="fade-up" data-aos-duration="1600" class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">EXPERTISE AND EXPERIENCE
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Professionals have
                        the necessary knowledge, skills, and experience to properly prepare the surface, apply the
                        overlay, and achieve high-quality results.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                <img src={pattern2} data-aos="fade-up" data-aos-duration="1600" class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init aos-animate">ACCESS TO SPECIALIZED EQUIPMENT</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">Professionals have
                        access to specialized tools and equipment specifically designed for concrete stamp overlay,
                        ensuring precise and consistent patterns and textures.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                <img src={pattern3} data-aos="fade-up" data-aos-duration="1600" class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init">COST SAVINGS IN THE LONG RUN
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">Improper installation can lead
                        to issues such as cracking, delamination, or premature wear. Professionals can avoid these
                        problems, saving you money on potential repairs or replacements in the future.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={timeeffe}  data-aos="fade-up" data-aos-duration="1600"
                        class="aos-init aos-animate"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800" class="aos-init">TIME EFFICIENCY</h2>
                    <p data-aos="fade-up" data-aos-duration="2000" class="aos-init">Professionals are equipped with
                        the manpower and expertise to complete the project efficiently, minimizing downtime and
                        inconvenience.</p>
                </div>
            </div>







        </div>
    </section>


    <div class="single-para aos-init aos-animate" data-aos="fade-up" data-aos-duration="1800">
        <p>By choosing a professional for the application of concrete stamp overlay, you can ensure a well-executed
            and visually appealing result that meets your expectations while avoiding potential pitfalls associated
            with inexperienced or DIY installations
        </p>
    </div>

</div>

</div>

<Footer/>
    </div>
    
  )
}


export default memo(ConcretOverlay) 