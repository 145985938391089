import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import React, { memo, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import remedation from "../../assets/images/remedation.png";

import gr1 from "../../assets/images/gr-1.png";
import gr2 from "../../assets/images/gr-2.png";
import gr3 from "../../assets/images/gr-3.png";
import gr4 from "../../assets/images/gr-4.png";
import gr5 from "../../assets/images/gr-5.png";
import gr6 from "../../assets/images/gr-6.png";
import gr7 from "../../assets/images/gr-7.png";
import Aesthetics from "../../assets/images/Aesthetics.png";
import sec3concretimg from "../../assets/images/sec-3-concret-img.png";
import stepspathbefore from "../../assets/images/steps-path-before.svg";
import steps1 from "../../assets/images/steps1.png";
import steps2 from "../../assets/images/steps2.png";
import steps3 from "../../assets/images/steps3.png";
import steps4 from "../../assets/images/steps4.png";
import steps5 from "../../assets/images/steps5.png";
import homeimg from "../../assets/images/home-img.png";
import refelct from "../../assets/images/refelct.png";
import concretevideo from "../../assets/video/concretcrek.mp4";
import application1 from "../../assets/images/application1.png";
import application2 from "../../assets/images/application2.png";
import application3 from "../../assets/images/application3.png";
import application4 from "../../assets/images/application4.png";

import im1 from "../../assets/images/im1.png";
import im2 from "../../assets/images/im1.png";
import im3 from "../../assets/images/im3.png";
import im4 from "../../assets/images/im4.png";
import involvestep2 from "../../assets/images/involvestep2.png";
import involvestep3 from "../../assets/images/involvestep3.png";
import involvestep4 from "../../assets/images/involvestep4.png";
import prof1 from "../../assets/images/prof1.png";
import prof3 from "../../assets/images/prof3.png";
import stactintegrity from "../../assets/images/stactintegrity.png";

function Concrete() {
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  return (
    <div>
      <Header />

      <div class="side-space con-creek">
        <section class="sec-1 concrete-sec-1">
          <div class="bg-video-wrap">
            <video src={concretevideo} loop muted autoPlay></video>
            <div class="overlay"></div>

            <div class="banner-content">
              <h1 class="">Concrete crack and repair</h1>
            </div>
          </div>
        </section>

        <div class="side-padding">
          <section class="sec-2-concret">
            <div
              class="inner-title"
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <h1>
                <span></span>Concrete remediation
              </h1>
              <div class="ul-li-concrete">
                <ul>
                  <li>Crack injection</li>
                  <li>Concrete stitches</li>
                  <li>Patching and repairs</li>
                  <li>
                    Reinforcement protection <br />
                    and preservation
                  </li>
                </ul>
              </div>
              <p>
                Concrete remediation refers to the process of repairing and
                restoring concrete structures that have deteriorated or been
                damaged over time. It is essential to address concrete issues
                promptly to maintain structural integrity, safety, and
                aesthetics. Here are some key aspects of concrete remediation,
                its applications, the importance of professional involvement,
                and the methods employed
              </p>
            </div>
            <div class="grind">
              <img src={remedation} />
            </div>
            <div
              class="inner-title vc aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1600"
              style={{ marginBottom: "2rem" }}
            >
              <h1>
                <span></span>Applications of Concrete Remediation
              </h1>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={application1}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Infrastructure
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Concrete remediation is crucial for bridges, highways, dams,
                    tunnels, and other large-scale infrastructure projects that
                    are subject to heavy use, weathering, and environmental
                    factors.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={application2}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Buildings
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    It is essential for commercial and residential structures,
                    including foundations, floors, walls, and facades, where
                    concrete deterioration can compromise the building's
                    stability and appearance.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={application3}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Industrial Facilities
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Concrete remediation is often needed in industrial settings
                    such as factories, warehouses, and manufacturing plants,
                    where harsh chemicals, heavy machinery, and high
                    temperatures can damage concrete surfaces.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={application4}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Parking Structures
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Parking garages and lots experience constant traffic,
                    exposure to moisture, and the use of chemicals,  leading to
                    concrete degradation and the need for remediation.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="inner-title vc aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1600"
              style={{ marginBottom: "4rem" }}
            >
              <h1>
                <span></span>Importance of Concrete Remediation
              </h1>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img src={im1} data-aos="fade-up" data-aos-duration="1600" />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Safety
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Deteriorating concrete can pose significant safety risks.
                    Cracks, spalling (flaking), or delamination can lead to
                    structural failure, trip hazards, or falling debris,
                    endangering people and property.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={stactintegrity}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Structural Integrity
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Concrete degradation weakens the overall structure, reducing
                    its load-bearing capacity and compromising its ability to
                    withstand environmental stresses such as earthquakes, wind,
                    or moisture.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img src={im3} data-aos="fade-up" data-aos-duration="1600" />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Durability
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Concrete remediation is often needed in industrial settings
                    such as factories, warehouses, and manufacturing plants,
                    where harsh chemicals, heavy machinery, and high
                    temperatures can damage concrete surfaces.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img src={im4} data-aos="fade-up" data-aos-duration="1600" />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Aesthetics
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Damaged or deteriorated concrete negatively impacts the
                    appearance of structures. Restoring the concrete not only
                    improves functionality but also enhances the overall visual
                    appeal.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="sec-3-concret tablet">
            <div class="inner-title v1">
              <h1 data-aos="fade-up" data-aos-duration="1800">
                <span></span>Methods Involved in Concrete Remediation
              </h1>
            </div>

            <div
              class="row"
              style={{
                position: "relative",
                justifyContent: "space-around",
                marginTop: "40px",
              }}
            >
              <span class="path-steps">
                <img src={stepspathbefore} />
              </span>
              <div class="col-lg-5 col-md-6">
                <div class="step-concret">
                  <img src={steps1} />
                  <strong>1</strong>
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    surface preparation
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    This involves cleaning the concrete surface, removing loose
                    materials, and preparing it for repair or application of
                    protective coatings.
                  </p>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="step-concret">
                  <img src={involvestep2} />
                  <strong>2</strong>
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Concrete Repair
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Repair methods can include patching, crack injection,
                    grouting, and overlaying damaged areas to restore the
                    integrity and strength of the concrete.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="row"
              style={{
                position: "relative",
                justifyContent: "space-around",
                marginTop: "40px",
              }}
            >
              <span class="path-steps">
                <img src={stepspathbefore} />
              </span>
              <div class="col-lg-5 col-md-6">
                <div class="step-concret">
                  <img src={involvestep3} />
                  <strong>3</strong>
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Corrosion Control
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    When reinforcing steel within concrete begins to corrode, it
                    can cause significant damage. Corrosion control methods
                    include applying corrosion inhibitors, cathodic protection,
                    or replacing corroded steel.
                  </p>
                </div>
              </div>

              <div class="col-lg-5 col-md-6">
                <div class="step-concret">
                  <img src={involvestep4} />
                  <strong>4</strong>
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Protective Coatings
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Applying protective coatings such as epoxy, urethane, or
                    acrylic sealants can enhance durability, protect against
                    moisture ingress, and improve aesthetics
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="sec-2-concret tablet s-4">
            <div
              class="inner-title"
              data-aos="fade-up"
              data-aos-duration="1800"
            >
              <h1>
                <span></span>Why Use a Professional for <br />
                Concrete Remediation
              </h1>
            </div>

            <div class="row" style={{ marginTop: "100px" }}>
              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={prof1}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Expertise
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    {" "}
                    Professionals have in-depth knowledge of concrete materials,
                    repair techniques, and industry standards. They can
                    accurately assess the extent of damage and provide suitable
                    solutions.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img src={im1} data-aos="fade-up" data-aos-duration="1600" />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Safety
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Concrete remediation often involves working at heights,
                    handling heavy equipment, or using specialized tools and
                    chemicals. Professionals are trained to ensure safety during
                    the entire process.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img
                    src={prof3}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Quality Assurance
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Professionals have access to high-quality materials,
                    advanced equipment, and specialized techniques, ensuring
                    long-lasting repairs and superior results.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                  <img src={gr5} data-aos="fade-up" data-aos-duration="1600" />
                  <h2 data-aos="fade-up" data-aos-duration="1800">
                    Cost-Effectiveness
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Although professional services may entail upfront costs,
                    they can save money in the long run by preventing further
                    damage and the need for extensive repairs or replacements.
                  </p>
                </div>
              </div>

              <div
                class="single-para"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <p>
                  In summary, concrete remediation is crucial for maintaining
                  the safety, structural integrity, and aesthetics of concrete
                  structures. Engaging professionals ensures expertise, safety,
                  quality, and cost-effectiveness in addressing concrete issues
                  through appropriate methods and techniques.
                </p>
              </div>
            </div>
          </section>

          {/* <div
            class="furter-inquiry"
            data-aos="fade-up"
            data-aos-duration="1800"
          >
            <p>For further insight and tailored specific projects.</p>
            <h5>Give us a call or email </h5>
            <p>
              {" "}
              <a href="maito:michael@solidificationsolutions.com.au">
                michael@solidificationsolutions.com.au
              </a>{" "}
            </p>
          </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default memo(Concrete);
