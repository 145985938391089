
import { Route, Routes,Outlet,Link } from 'react-router-dom';
import './assets/css/circle.css';
import '../src/assets/css/responsive.css'

import '../src/assets/css/style.css';
import Index from './pages/Home/Index';
import 'bootstrap/dist/css/bootstrap.min.css';
// import {Routes,Route,Outlet,Link} from "react-router-dom";
import About from './pages/About/About';
import Timber from './pages/Timber/Timber';
import Epoxy from './pages/Expory/Epoxy';
import Contact from './pages/Contact/Contact';
import Concrete from './pages/Home/Concrete';
import ConcreteGranding from './pages/Home/ConcreteGranding';
import ConcretOverlay from './pages/Concret-overlay/ConcretOverlay';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import CircleMenu from './Components/CircleMenu';
// import Loader from './Components/Loader';

function App() {
 

  return (
    <Routes>
    <Route path="/" element={<Index/>}/>
    <Route path="/About" element={<About/>}/>
    <Route path="/timber" element={<Timber/>}/>
    <Route path="/epoxy" element={<Epoxy/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/circle" element={<CircleMenu/>}/>
    {/* <Route path="/loader" element={<Loader/>}/> */}
    <Route path="/concrete" element={<Concrete/>}/>
    <Route path="/concretegranding" element={<ConcreteGranding/>}/>
    <Route path="/concretoverlay" element={<ConcretOverlay/>}/>
    </Routes>
  );
}

export default App;
