import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import React, { useEffect } from 'react';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import timbervideo1 from "../../assets/video/Tm-video.mp4"
import Timberimg1 from "../../assets/images/Timber-img-1.png"
import Aesthetics from "../../assets/images/Aesthetics.png"
import gr1 from "../../assets/images/gr-1.png"
import safe from "../../assets/images/Safe.png"
import gr5 from "../../assets/images/gr-5.png"
import ad1 from "../../assets/images/ad-1.png"
import ad2 from "../../assets/images/ad-2.png"
import ad3 from "../../assets/images/ad-3.png"
import ad4 from "../../assets/images/ad-4.png"
import water from "../../assets/images/water.png"
import epicon2 from "../../assets/images/ep-icon2.png"
import gr7 from "../../assets/images/gr-7.png"





function Timber() {



    useEffect(() => {
        AOS.init({
          // AOS configuration options
        });
    
        // Refresh AOS when component mounts
        AOS.refresh();
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const handleScroll = () => {
        // Refresh AOS on scroll to trigger animations
        AOS.refresh();
      };

      
  return (
    
    <div>
   <Header/>
   <div className="side-space timber-pg">

<section className="sec-1 concrete-sec-1">
    <div className="bg-video-wrap">
    <video src={timbervideo1} loop muted autoPlay>
            </video>
        <div className="overlay">
        </div>

        <div className="banner-content">
            <h1 className="">Timber</h1>




        </div>


    </div>

</section>


<div className="side-padding">


    <section className="sec-2-concret">

        <div className="inner-title">
            <h1 data-aos="fade-up" data-aos-duration="1800"><span></span>TIMBER FLooring</h1>
            <p data-aos="fade-up" data-aos-duration="2000">Getting your timber floor sanded and polished offers several <br/> advantages, and hiring a professional
                for the job ensures the <br/>best results. Additionally, using a polyurethane coating<br/> provides its own
                set of benefits. </p>

        </div>
        <div className="grind">
            <img src={Timberimg1}/>
        </div>
        <p className="oneline-para pbapna" data-aos="fade-up" data-aos-duration="1800">Here are the advantages of getting your timber floor sanded and polished and why
            it's beneficial to choose a
            professional, along with the advantages of using a polyurethane coating</p>
        <div className="inner-title" data-aos="fade-up" data-aos-duration="1600">
            <h1 ><span></span>Advantages OF TIMBER FLOOR SANDED AND POLISHED</h1> 


        </div>
        <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={Aesthetics}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Restores the beauty</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Over time, timber floors can become worn, scratched, or dull.
                        Sanding and polishing help restore their original beauty,
                        bringing back the natural colour and grain of the wood.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={gr1}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Enhances durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Sanding removes imperfections, such as scratches,
                        dents, or stains, making the floor more durable
                        and resistant to daily wear and tear. Polishing
                        adds a protective layer, further increasing its
                        resilience.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={safe}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Improves hygiene</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Timber floors can harbor dust, allergens, and bacteria in small
                        cracks and crevices. Sanding removes these particles, making
                        the floor cleaner and healthier, especially for individuals with
                        allergies or respiratory sensitivities.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={gr5} data-aos="fade-up" data-aos-duration="1600" />
                    <h2 data-aos="fade-up" data-aos-duration="1800">Increases property value</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">A well-maintained timber floor adds value to your property.
                        Sanding and polishing can significantly enhance the overall
                        aesthetic appeal, making your home more attractive to
                        potential buyers or renters..</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={ad1}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Cost-effective</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Sanding and polishing your existing timber floor is often more
                        cost-effective than installing a new floor. It allows you to
                        revitalize your floor without the expense of replacing it
                        entirely.</p>
                </div>
            </div>



        </div>
    </section>



    <section className="sec-2-concret s-4 timber-sec4">
        <div className="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span> Advantages of choosing a professional <br/>
                for sanding and polishing</h1>


        </div>

        <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={ad1}  data-aos="fade-up" data-aos-duration="1800"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Expertise and experience</h2>
                    <p data-aos="fade-up" data-aos-duration="2000"> Professional floor sanders have the necessary knowledge, skills,
                        and experience to handle the job effectively. They know how to
                        assess the condition of the floor, select the appropriate
                        sanding techniques and equipment, and achieve
                        optimal results.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={ad2}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Quality equipment and materials</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professionals use high-quality sanding machines,
                        abrasives, and coatings, ensuring a superior finish.
                        They have access to professional-grade products
                        that may not be readily available to the
                        general public.</p>
                </div>
            </div>

            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={ad3}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Time-saving</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Professionals can complete the sanding and polishing
                        process efficiently, saving you time and effort. They
                        have the necessary tools and expertise to work
                        quickly without compromising on quality.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={ad4}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Minimizes mistakes and damage</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Sanding and polishing require precision to achieve the
                        desired results. Professionals are less likely to make
                        mistakes or cause damage to the floor, ensuring a
                        smooth and flawless finish.</p>
                </div>
            </div>





        </div>
    </section>


    <section className="sec-2-concret s-4">
        <div className="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span>Advantages of USING a polyurethane coating</h1>


        </div>

        <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={gr1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polyurethane coatings provide excellent durability, offering
                        protection against scratches, stains, and general wear
                        and tear. They create a hard, long-lasting finish that
                        can withstand heavy foot traffic.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={water} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Water resistance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000"> Polyurethane coatings are resistant to water and
                        moisture, making them suitable for areas prone
                        to spills or high humidity. They form a protective
                        barrier that prevents water from penetrating
                        the wood and causing damage</p>
                </div>
            </div>

            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={epicon2}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Easy maintenance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polyurethane-coated floors are relatively low maintenance.
                        They can be easily cleaned with a damp cloth or mop, and
                        routine maintenance typically involves simple sweeping
                        or vacuuming to remove dust and debris.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={Aesthetics}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Enhanced appearance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000"> Polyurethane coatings bring out the natural beauty
                        of the timber, enhancing its colour and grain.
                        They provide a glossy or satin finish, giving
                        the floor a polished and refined look.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="Grand-content">
                    <img src={gr7}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Versatility</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polyurethane coatings come in various finishes, ranging
                        from high-gloss to matte, allowing you to choose the
                        level of shine that suits your aesthetic preferences.</p>
                </div>
            </div>



            <div className="single-para" data-aos="fade-up" data-aos-duration="1800">
                <p>It's important to note that while sanding and polishing can be a DIY project, hiring a
                    professional ensures a high-quality outcome and minimizes the risk of errors or damage to your
                    floor. Professionals have the expertise, equipment, and materials to achieve the best results,
                    ultimately prolonging the life and appearance of your timber floor.
                </p>
            </div>
        </div>
    </section>


    <div className="furter-inquiry">
        <p data-aos="fade-up" data-aos-duration="1600">For further insight and tailored specific projects.</p>
        <h5 data-aos="fade-up" data-aos-duration="1800">Give us a call or email </h5>
        <p data-aos="fade-up" data-aos-duration="2000"> <a href="maito:michael@solidificationsolutions.com.au">michael@solidificationsolutions.com.au</a> </p>
    </div>

</div>

</div>
<Footer/>
    </div>
  )
}

export default Timber