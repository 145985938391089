import React, { memo, useState } from 'react';
import '../assets/css/circle.css'; // Import the CSS file for styling
import { Link } from "react-router-dom";


function CircleMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {     
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="circle-menu">
      {/* Main circle */}
      <div className={`circle ${isOpen ? 'open' : ''}`} onClick={handleClick}>
        {/* Content inside the main circle */}
        <span>Click</span>
      </div>

      {/* Additional circles */}
      {isOpen && (
        <React.Fragment>
          <div className="circle upper-circle">
            {/* Content inside the upper circle */}
            <Link to=""> <span>Option 1</span></Link>
          </div>
          <div className="circle upper-circle">
            {/* Content inside the upper circle */}
            <span>Option 2</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}


export default memo(CircleMenu) 
