import React, { memo, useEffect } from 'react'
import logo from "..//assets/images/header-logo.png";


function Loader() {
    useEffect(() => {
        const loaderPage = document.querySelector(".loader-page");
        const counter = document.querySelector(".loader-counter");
        const fill = document.querySelector(".loader-fill");
        const duration = 1500; // Duration in milliseconds (3 seconds)
        const increment = 100 / (duration / 50); // Increment value per 50 milliseconds
    
        let amount = 0;
        const interval = setInterval(loop, 50);
    
        function loop() {
          if (amount >= 100) {
            clearInterval(interval);
            loaderPage.style.visibility = "hidden";
            loaderPage.style.opacity = "0";
          } else {
            amount += increment;
            fill.style.width = amount + "%";
     
          }
        }
      }, []);
      
  return (
    <div>
        
        <div class="loader-page">
        <div class="loader-container">
           
            <div class="loader">
             
              <img src={logo} alt="" />
                <div class="loader-fill"></div>
            </div>
        </div>
    </div> 
    </div>
  )
}

export default memo(Loader) 