import React, { useEffect, useState, useRef, memo } from "react";

import { gsap } from "gsap";
import ScrollTrigger from "gsap";
import "./Header.css";
import callHeader from "../../assets/images/call-header.png";
import emailHeader from "../../assets/images/email-header.png";
import logo from "../../assets/images/header-logo.png";
import social1 from "../../assets/images/social1.svg";
import social2 from "../../assets/images/social2.svg";
import social3 from "../../assets/images/social3.svg";
import social4 from "../../assets/images/social4.svg";
import bannerslide1 from "../../assets/images/banner-slide-1.png";
import bannerslide2 from "../../assets/images/banner-slide-2.png";
import bannerslide3 from "../../assets/images/banner-slide-3.png";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function Header() {
  const [toggle, setToggle] = useState("collapsed");
  useEffect(() => {
    if (toggle === "expanded") {
      document.body.classList.add("nav");
    } else {
      document.body.classList.remove("nav");
    }
  }, [toggle]);

  var body = document.body;
  body.classList.add("over-remove");


    
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

  return (

    <div>
         
      <div class="over-remove">
        {/* <!-- <div class="loader-page">
        <div class="loader-container">
            <div class="loader-counter">
                <p>0%</p>
            </div>
            <div class="loader">
                <div class="loader-fill"></div>
            </div>
        </div>
    </div> --> */}

        <main>
          <header class="header-ab">
            <nav class={toggle}>
              <div class="logo-head">
                <Link to="/">
                  {" "}
                  <img src={logo} alt="" />
                </Link>
              </div>

              <div id="menu">
                <div
                  className={`burger ${toggle}`}
                  onClick={() => {
                    setToggle(
                      toggle === "collapsed" ? "expanded" : "collapsed"
                    );
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p>menu</p>
              </div>

              <div class="tabs-header">
                <div class="header-contat-info">
                  <a href="tel:0451769151" >
                    <img src={callHeader} alt="" />
                    <span>0451769151</span>
                  </a>
                  <a href="mailto:info@solidificationsolutions.com.au">
                    <img src={emailHeader} alt="" />
                    <span>info@solidificationsolutions.com.au</span>
                  </a>
                </div>
                <div class="slider-container">
                  {/* <!-- <div class="slider">
              <div class="slide">
                <img src="assets/images/banner-slide-1.png" class="img-expand" alt="">
                <h2>CONCRETE</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-2.png" class="img-expand" alt="">
                <h2>EPOXY</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-3.png"  class="img-expand" alt="">
                <h2>TIMBER</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-1.png" class="img-expand" alt="">
                <h2>CONCRETE</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-2.png" class="img-expand" alt="">
                <h2>EPOXY</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-3.png" class="img-expand" alt="">
                <h2>TIMBER</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-1.png" class="img-expand" alt="">
                <h2>CONCRETE</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-2.png" class="img-expand" alt="">
                <h2>EPOXY</h2>
              </div>
              <div class="slide">
                <img src="assets/images/banner-slide-3.png" class="img-expand" alt="">
                <h2>TIMBER</h2>
              </div>
            </div> --> */}
                </div>

                <div class="swiper-container">
                  <div className="c-nav_products-list o-layout js-list swiper-wrapper">
                    <Swiper
                      slidesPerView={1}
                      centeredSlides={false}
                      pagination={{ clickable: true }}
                      mousewheel={{ forceToAxis: true }}
                      className="mySwiper"
                    >
                        
                     
                      <SwiperSlide>
                        <div className="swiper-slide hover-eeff">
                          <Link
                            className="c-nav_product-link o-layout_item js-product -visible"
                            draggable="false"
                            data-categories="freestanding"
                            data-pjax-state=""
                            style={{}}
                            to=""
                          >
                            <span className="c-nav_product-link_wrap">
                              <span className="c-nav_product-link_background">
                                <span
                                  className="o-background"
                                  style={{
                                    backgroundImage: `url(${bannerslide1})`,
                                  }}
                                ></span>
                              </span>
                            </span>

                            <span className="c-nav_product-link_name o-h1">
                              CONCRETE
                              <div className="drp-iner">
                                <ul>
                                  <li>
                                    <Link to="/ConcretOverlay">
                                      CONCRETE OVERLAYS
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/ConcreteGranding">
                                      CONCRETE GRINDING and POLISHING
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/Concrete">
                                    Concrete crack and repair
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </Link>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="swiper-slide">
                          <Link
                            className="c-nav_product-link o-layout_item js-product -visible"
                            draggable="false"
                            data-categories="freestanding"
                            data-pjax-state=""
                            style={{}}
                            to="/epoxy"
                          >
                            <span className="c-nav_product-link_wrap">
                              <span className="c-nav_product-link_background">
                                <span
                                  className="o-background"
                                  style={{
                                    backgroundImage: `url(${bannerslide2})`,
                                  }}
                                ></span>
                              </span>
                            </span>
                            <span className="c-nav_product-link_name o-h1">
                              EPOXY
                            </span>
                          </Link>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="swiper-slide">
                          <Link
                            className="c-nav_product-link o-layout_item js-product -visible"
                            draggable="false"
                            data-categories="freestanding"
                            data-pjax-state=""
                            to="/timber"
                          >
                            <span className="c-nav_product-link_wrap">
                              <span className="c-nav_product-link_background">
                                <span
                                  className="o-background"
                                  style={{
                                    backgroundImage: `url(${bannerslide3})`,
                                  }}
                                ></span>
                              </span>
                            </span>
                            <span className="c-nav_product-link_name o-h1">
                              TIMBER
                            </span>
                          </Link>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>

                <div class="headMeanus">
                  <ul>
                    <li>
                      <Link to="/About">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="social-icons">
              <Link to='https://www.facebook.com/'> <img src={social1} alt="" /></Link> 
              <Link to='https://www.instagram.com/'>  <img src={social2} alt="" /></Link> 
              <Link to='https://www.linkedin.com/'><img src={social3} alt="" /></Link> 
              <Link to='https://www.youtube.com/'><img src={social4} alt="" /></Link> 
              </div>
            </nav>
          </header>
        </main>
      </div>
    </div>
  );
}


export default memo(Header) 