import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import React, { memo, useEffect, useState } from 'react';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import video4 from "../../assets/video/home-banner.mp4"
import { Modal } from 'react-bootstrap';

import close from "../../assets/images/close.png"
function Contact() {
  const [showModal, setShowModal] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);

  const handleShowModal = () => {
    if (inputValue1.trim() === '' || inputValue2.trim() === '' || inputValue3.trim() === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeInput1 = (event) => {
    setInputValue1(event.target.value);
  };

  const handleChangeInput2 = (event) => {
    setInputValue2(event.target.value);
  };

  const handleChangeInput3 = (event) => {
    setInputValue3(event.target.value);
  };

  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };


  return (
    <div>
      <Header />
      <div className="side-space contact-page">
        <section className="sec-1 concrete-sec-1">
          <div className="bg-video-wrap">
            <video src={video4} loop muted autoPlay></video>
            <div className="overlay"></div>
            <div className="banner-content">
              <h1 className="">CONTACT Us</h1>
            </div>
          </div>
        </section>

        <div className="side-padding  ">
          <section className="sec-1-contact">
            <div className="row">
              <div className="col-lg-6 col-md-4">
                <h1 className="home-title">
                  Let us
                  <br />
                  KNOW
                  <br />
                  ABOUT YOUR
                  <br />
                  project
                </h1>
              </div>
              <div className="col-lg-6 col-md-8">
                <form action="" className="contact-form ">
                  <input
                    type="text"
                    value={inputValue1}
                    onChange={handleChangeInput1}
                    placeholder="Name"
                    required
                    className={!isFormValid && inputValue1.trim() === '' ? 'red-text' : ''}
                  />
                  <input
                    type="number"
                    value={inputValue2}
                    onChange={handleChangeInput2}
                    placeholder="Contact Number"
                    required
                    className={!isFormValid && inputValue2.trim() === '' ? 'red-text' : ''}
                  />
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={inputValue3}
                    onChange={handleChangeInput3}
                    required
                    className={!isFormValid && inputValue3.trim() === '' ? 'red-text' : ''}
                  ></textarea>
                  {!isFormValid && <p className="red-text">Please fill all fields.</p>}
                  <button 
                    type="button"
                    className="btn btn-primary"
                    onClick={handleShowModal}
                  >
                    Submit
                  </button>
                  
                  <Modal className='thanks' show={showModal } onHide={handleCloseModal}>
                    <Modal.Header closeButton>
               
                    </Modal.Header>
                    <Modal.Body>
                      <div className="modal-body thanks">
                        <div className="concert-content">
                          <h1 className="home-title">THANK YOU</h1>
                          <p>Your form has been submitted</p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}


export default memo(Contact) 
        