import React, { memo, useState } from 'react'
import emailHeader from "../../assets/images/email-header.png"
import callHeader from "../../assets/images/call-header.png"
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'
import Loader from '../../Components/Loader';
import plusicons11 from "../../assets/images/plus-icons11.svg"
import plusicons1122 from "../../assets/images/plus-icons1122.svg"


function Footer() {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((prev) => !prev);
    };

    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <>
            <Loader />
            <div class="center">
                <div class="box">
                    <ul>
                        <li><a href="#">

                            <span>Contact Us</span>
                        </a>
                        </li>
                        <li><a href="#">

                            <span>About Us</span>
                        </a>
                        </li>

                    </ul>
                </div>
                <div class="circle-menu">
                    <div className={`circle ${isOpen ? 'open' : ''}`} onClick={handleClick}>
                        {/* Content inside the main circle */}
                        <span>{isOpen ? <img src={plusicons1122} /> : <img src={plusicons11} />}</span>
                    </div>
                    {isOpen && (
                        <React.Fragment>
                            <div className="circle upper-circle">
                                {/* Content inside the upper circle */}
                                <Link to="/Contact">   <span>Contact Us</span></Link>
                            </div>
                            <div className="circle upper-circle">
                                {/* Content inside the upper circle */}
                                <Link to="/About"> <span>About us</span></Link>
                            </div>
                        </React.Fragment>
                    )}

                </div>
            </div>



            <footer>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-6">
                        <div class="footer-content">
                            <h1 class="home-title" data-aos="fade-up" data-aos-duration="1500">Where <br />
                                Elegance<br />
                                Meets<br />
                                Functionality</h1>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-6">
                        <div class="footer-subcribe">
                            <h4>SUBSCRIBE TO OUR NEWSLETTER</h4>
                            <form action="">
                                <input type="text" placeholder="Email" />
                                <a href="">Subscribe</a>
                            </form>
                            <ul>
                                <li><Link to="/about" onClick={handleLinkClick}>About Us</Link></li>
                                <li><Link to="/contact" onClick={handleLinkClick}>Contact Us</Link></li>
                                <li><Link to="/ConcretOverlay" onClick={handleLinkClick}>Concrete Overlay</Link></li>
                                <li><Link to="/epoxy" onClick={handleLinkClick}>Epoxy Flooring</Link></li>
                                <li><Link to="/timber" onClick={handleLinkClick}>Timber Flooring</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="header-contat-info">
                    <a href="tel:0451769151"><img src={callHeader} /><span>0451769151</span></a>
                    <a href="mailto:info@solidificationsolutions.com.au"><img src={emailHeader} /><span>info@solidificationsolutions.com.au</span></a>
                </div>

            </footer>
            <div class="copyright " style={{ zIndex: '9999999999' }}>
                <p>© 2023 Solidification Solutions | All Rights Reserved</p>
                <p className='whodesign'><Link to="https://techciaga.com.au/" target="_blank" style={{ color: "white", textDecoration: "none" }}>Who Designed This Website?</Link></p>
                <ul>
                    <li><a href="">Terms & Conditions</a></li>
                    <li><a href=""> Privacy Policy</a></li>
                    <li><a href=""> Cookie Policy </a></li>
                </ul>
            </div>
            <div className='onlymobadd'>
                <p>© 2023 Solidification Solutions | All Rights Reserved</p>
            </div>
            {/* </main> */}



            {/* // <!-- Modal --> */}
            <div class="modal thanks fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                src="assets/images/close.png" /></button>
                        </div>
                        <div class="modal-body">
                            <div class="concert-content">
                                <h1 class="home-title">THANK YOU</h1>
                                <p>Your form has been submitted</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            {/* <!-- Modal --> */}
            <div class="modal ab-modal  fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                src="assets/images/close.png" /></button>
                        </div>
                        <div class="modal-body">
                            <div class="inner-title">
                                <h1><span></span>About Us</h1>

                                <p>Welcome to Solidification Solutions, your premier destination for all your concrete and timber
                                    flooring needs. With a passion for craftsmanship and a commitment to excellence, we specialize
                                    in providing top-quality concrete overlays, concrete grinding and polishing, epoxy coatings, and
                                    timber sanding and polishing services. <br /><br />

                                    At Solidification Solutions, we understand that your floors are a vital aspect of your space,
                                    adding both aesthetic appeal and functionality. That's why we pride ourselves on delivering
                                    exceptional results that transform ordinary floors into extraordinary works of art.
                                    <br /><br />
                                    With years of industry experience, our team of skilled professionals possesses the expertise and
                                    knowledge required to tackle projects of any size and complexity. Whether you're looking to
                                    enhance your residential or commercial space, we have the expertise to turn your vision into
                                    reality.
                                    <br /><br />
                                    Our concrete overlays offer a versatile and cost-effective solution to revitalize your existing
                                    concrete surfaces. From driveways and patios to interior floors, our overlays are designed to
                                    provide a durable and visually stunning finish that surpasses your expectations.
                                    <br /><br />
                                    If your concrete floors are in need of restoration or enhancement, our concrete grinding and
                                    polishing services are tailored to meet your unique requirements. Using state-of-the-art
                                    equipment and techniques, we carefully remove imperfections, smooth out surfaces, and apply
                                    high-quality finishes to create floors that are both durable and visually appealing.
                                    <br /><br />
                                    In addition to concrete services, we also specialize in epoxy coatings. Our epoxy coatings are a
                                    fantastic way to protect your floors while adding a touch of elegance. Whether you need an epoxy
                                    coating for your garage, warehouse, or commercial space, we offer a wide range of colours and
                                    finishes to suit your style and functional needs.
                                    <br /><br />
                                    Furthermore, we are experts in timber sanding and polishing. We understand the timeless beauty
                                    and warmth that timber floors bring to a space. Our meticulous sanding and polishing techniques
                                    breathe new life into worn-out timber, restoring its natural splendour and enhancing the overall
                                    ambiance of your space.
                                    <br /><br />
                                    At Solidification Solutions, we prioritize customer satisfaction above all else. We strive to
                                    provide personalized service, working closely with you throughout the entire process to ensure
                                    that your specific needs and desires are met. We take pride in our attention to detail, superior
                                    craftsmanship, and commitment to delivering exceptional results on time and within budget.
                                    <br /><br />
                                    When you choose Solidification Solutions, you're choosing a team dedicated to quality,
                                    professionalism, and customer satisfaction. Let us transform your concrete and timber floors
                                    into stunning masterpieces that will leave a lasting impression. Contact us today for a
                                    consultation and let us bring your flooring dreams to life!</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* </div> */}





            {/* <!-- Modal --> */}
            <div class="modal ab-modal  fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel3"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                src="assets/images/close.png" /></button>
                        </div>
                        <div class="modal-body">
                            <div class="inner-title">


                                <p>Welcome to Solidification Solutions, your premier destination for all your concrete and timber
                                    flooring needs. With a passion for craftsmanship and a commitment to excellence, we specialize
                                    in providing top-quality concrete overlays, concrete grinding and polishing, epoxy coatings, and
                                    timber sanding and polishing services. <br /><br />

                                    At Solidification Solutions, we understand that your floors are a vital aspect of your space,
                                    adding both aesthetic appeal and functionality. That's why we pride ourselves on delivering
                                    exceptional results that transform ordinary floors into extraordinary works of art.
                                    <br /><br />
                                    With years of industry experience, our team of skilled professionals possesses the expertise and
                                    knowledge required to tackle projects of any size and complexity. Whether you're looking to
                                    enhance your residential or commercial space, we have the expertise to turn your vision into
                                    reality.
                                    <br /><br />
                                    Our concrete overlays offer a versatile and cost-effective solution to revitalize your existing
                                    concrete surfaces. From driveways and patios to interior floors, our overlays are designed to
                                    provide a durable and visually stunning finish that surpasses your expectations.
                                    <br /><br />
                                    If your concrete floors are in need of restoration or enhancement, our concrete grinding and
                                    polishing services are tailored to meet your unique requirements. Using state-of-the-art
                                    equipment and techniques, we carefully remove imperfections, smooth out surfaces, and apply
                                    high-quality finishes to create floors that are both durable and visually appealing.
                                    <br /><br />
                                    In addition to concrete services, we also specialize in epoxy coatings. Our epoxy coatings are a
                                    fantastic way to protect your floors while adding a touch of elegance. Whether you need an epoxy
                                    coating for your garage, warehouse, or commercial space, we offer a wide range of colours and
                                    finishes to suit your style and functional needs.
                                    <br /><br />
                                    Furthermore, we are experts in timber sanding and polishing. We understand the timeless beauty
                                    and warmth that timber floors bring to a space. Our meticulous sanding and polishing techniques
                                    breathe new life into worn-out timber, restoring its natural splendour and enhancing the overall
                                    ambiance of your space.
                                    <br /><br />
                                    At Solidification Solutions, we prioritize customer satisfaction above all else. We strive to
                                    provide personalized service, working closely with you throughout the entire process to ensure
                                    that your specific needs and desires are met. We take pride in our attention to detail, superior
                                    craftsmanship, and commitment to delivering exceptional results on time and within budget.
                                    <br /><br />
                                    When you choose Solidification Solutions, you're choosing a team dedicated to quality,
                                    professionalism, and customer satisfaction. Let us transform your concrete and timber floors
                                    into stunning masterpieces that will leave a lasting impression. Contact us today for a
                                    consultation and let us bring your flooring dreams to life!</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* </div> */}





        </>
    )
}


export default memo(Footer) 