import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import React, { memo, useEffect } from 'react';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import gr1 from "../../assets/images/gr-1.png"
import gr2 from "../../assets/images/gr-2.png"
import gr3 from "../../assets/images/gr-3.png"
import gr4 from "../../assets/images/gr-4.png"
import gr5 from "../../assets/images/gr-5.png"
import gr6 from "../../assets/images/gr-6.png"
import gr7 from "../../assets/images/gr-7.png"
import grindimg from "../../assets/images/grind-img.png"  
import sec4concret from "../../assets/images/sec-4-concret.png"  
import concretepolishing from "../../assets/images/concretepolishing.png"
import refelct from "../../assets/images/refelct.png"
import concretegrinding from "../../assets/video/concretegrinding.mov"
import homeimg from "../../assets/images/home-img.png"
import Aesthetics from "../../assets/images/Aesthetics.png"

function ConcreteGranding() {



    useEffect(() => {
        AOS.init({
          // AOS configuration options
        });
    
        // Refresh AOS when component mounts
        AOS.refresh();
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const handleScroll = () => {
        // Refresh AOS on scroll to trigger animations
        AOS.refresh();
      };

      
  return (
    
    <div>
   <Header/>

   <div class="side-space">

<section class="sec-1 concrete-sec-1">
    <div class="bg-video-wrap">
    <video src={concretegrinding} loop muted autoPlay>
            </video>
        <div class="overlay">
        </div>

        <div class="banner-content" >
            <h1 class="">CONCRETE GRINDING AND POLISHING</h1>




        </div>


    </div>

</section>


<div class="side-padding">


    <section class="sec-2-concret">

        <div class="inner-title" data-aos="fade-up" data-aos-duration="1600">
            <h1 ><span></span>CONCRETE GRIND AND SEAL</h1>
            <p>Concrete grinding and sealing offer several advantages for <br/> both residential and commercial
                applications.</p>

        </div>
        <div class="grind">
            <img src={grindimg} />
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Enhanced Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete grinding removes surface
                        imperfections, such as cracks, stains,
                        and unevenness, thereby creating a
                        smoother and more durable surface.
                        The process also helps to strengthen
                        the concrete, making it less prone to
                        damage from heavy traffic, abrasion,
                        or moisture.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr2}   data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Improved Appearance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Grinding and sealing concrete can greatly
                        enhance its aesthetic appeal. By removing
                        blemishes and exposing the underlying
                        aggregate or creating a smooth polished
                        finish, the concrete can take on a clean,
                        modern, and professional look.
                        Additionally, sealing the concrete adds a
                        protective layer and can enhance the colour
                        and shine of the surface.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr3}   data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Increased Safety</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Uneven or damaged concrete surfaces
                        can pose safety hazards, such as trip and
                        slip risks. Concrete grinding eliminates
                        these issues by levelling the surface and
                        creating a uniform texture, reducing the
                        likelihood of accidents. Furthermore, the
                        application of a sealant can improve traction,
                        making the surface less slippery and safer to
                        walk on, especially in wet conditions.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr4}   data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Ease of Maintenance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Sealed concrete is easier to clean and maintain
                        compared to unsealed surfaces. The sealant forms
                        a protective barrier that helps repel stains, dirt, oil,
                        and other contaminants, making it simpler to wipe
                        or mop away spills. Regular cleaning and maintenance
                        are generally less time-consuming and require fewer
                        harsh chemicals or cleaning agents.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr5}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Cost-Effective Solution</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete grinding and sealing can be a cost-effective
                        alternative to other flooring options. Instead of completely
                        replacing a damaged concrete surface, grinding can
                        rejuvenate and repair it at a fraction of the cost.
                        The durability and low maintenance of sealed concrete
                        also contribute to long-term cost savings.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr6}   data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Environmental Friendliness</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete grinding and sealing are environmentally
                        friendly practices. By utilizing the existing concrete
                        surface, it eliminates the need for additional materials,
                        reducing waste and environmental impact. Furthermore,
                        the use of low or zero VOC sealants ensures minimal release
                        of harmful chemicals into the environment.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr7}   data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Versatility</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete grinding and sealing offer versatility in design
                        options. The process can be customized to achieve different
                        levels of aggregate exposure, from a light sand-like finish
                        to a high-gloss polished appearance. This allows for various
                        decorative possibilities and the ability to match the concrete
                        to the overall design scheme of the space.</p>
                </div>
            </div>

            <div class="single-para" data-aos="fade-up" data-aos-duration="2000">
                <p>It's important to note that the specific advantages may vary depending on the condition of the
                    concrete,
                    the grinding and sealing techniques used, and the quality of materials employed.
                </p>
            </div>
        </div>
    </section>



    <section class="sec-2-concret s-4">
        <div class="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span> CONCRETE Polishing</h1>
            <p>Concrete polishing is a process of refining and enhancing the surface of a concrete floor to achieve
                a smooth, glossy, and durable finish. It involves using diamond-impregnated discs to grind, hone,
                and polish the concrete surface.
            </p>

        </div>
        <div class="grind">
            <img src={sec4concret} />
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polished concrete is highly durable and long-lasting.
                        The process strengthens the concrete surface by
                        densifying it, making it more resistant to wear,
                        abrasion, and impact. It can withstand heavy
                        foot traffic and machinery without showing
                        signs of wear and tear.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr4} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Low maintenance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polished concrete is relatively easy to maintain compared to
                        other flooring options. The smooth, dense surface is resistant
                        to stains, dust, and dirt, making it simple to clean. Regular
                        sweeping and occasional mopping are usually sufficient
                        to keep the floor looking great.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr5} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Cost-Effective Solution</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete polishing is a cost-effective flooring solution.
                        Since it utilizes the existing concrete slab, there is no
                        need for additional materials such as tiles, carpets,
                        or coatings. It also reduces the need for ongoing
                        maintenance, saving on long-term costs.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={Aesthetics}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Aesthetics</h2>
                    <p data-aos="fade-up" data-aos-duration="2000"> Polished concrete floors offer a sleek and modern appearance
                        that can enhance the overall aesthetics of a space. The glossy
                        finish reflects light, brightening up the area and creating a
                        visually appealing ambiance. Various decorative options,
                        such as colouring can be incorporated to customize the
                        floor's look.</p>
                </div>
            </div>


            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr6}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Environmental Friendliness</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete polishing is considered an eco-friendly flooring
                        option. It minimizes waste by utilizing the existing concrete
                        slab and avoids the use of additional materials that may
                        have environmental impacts. The process also eliminates
                        the need for continuous coatings or chemical treatments,
                        reducing the release of harmful substances.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={homeimg}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Improved indoor air quality</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Polished concrete does not harbor dust mites, allergens,
                        or mould, which can be a concern with carpeted or tiled
                        floors. The smooth and seamless surface prevents the
                        accumulation of dust and allows for easy cleaning,
                        leading to better indoor air quality.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr7}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Versatility</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete polishing is suitable for various environments,
                        including residential, commercial, and industrial spaces.
                        It can be applied in areas such as homes, offices, retail
                        stores, warehouses, showrooms, and more. The process
                        can be customized to meet specific design requirements
                        and can be done in a range of finishes, from matte
                        to high-gloss</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={refelct}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Reflective Properties</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">The high-gloss finish of polished concrete can help maximize
                        natural lighting in a space. By reflecting light, it reduces the
                        need for artificial lighting during the day, resulting in
                        potential energy savings.</p>
                </div>
            </div>


            <div class="single-para" data-aos="fade-up" data-aos-duration="1800">
                <p>Due to its numerous advantages, concrete polishing is recommended as a durable, low-maintenance,
                    cost-effective, and aesthetically pleasing flooring option for both new constructions and
                    renovations. <br/>
                    However, it's important to note that the success of the polishing process depends on the
                    condition and quality of the existing concrete slab, so proper evaluation and preparation are
                    essential before proceeding with the polishing.
                </p>
            </div>
        </div>
    </section>

    <div class="furter-inquiry" data-aos="fade-up" data-aos-duration="1800">
      <p>For further insight and tailored specific projects.</p>
      <h5>Give us a call or email </h5>
      <p> <a href="maito:michael@solidificationsolutions.com.au">michael@solidificationsolutions.com.au</a>  </p>
    </div>

</div>

</div>

<Footer/>
    </div>
    
  )
}


export default memo(ConcreteGranding) 