import AOS from "aos";
import video4 from "../../assets/video/home-banner.mp4";
import "../../../node_modules/aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import concrethome from "../../assets/images/concrethome.png";
import Epoxyfactory from "../../assets/images/Epoxyfactory-img1.png";
import Epoxyfactory2 from "../../assets/images/Epoxyfactory-img2.png";
import Timber from "../../assets/images/Timber-img-1-florring.png";
import Timber2 from "../../assets/images/Timber-img-2.png";
import homefirstimg from "../../assets/images/home-first-img.png";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import { memo } from "react";
import { Modal } from "react-bootstrap";
import ConcreteOverlay from "../../assets/images/tabletimg/ConcreteOverlay.png";
import Crackimg from "../../assets/images/tabletimg/Crackimg.png";
import GRINDimg from "../../assets/images/tabletimg/GRINDimg.png";
import epoxytablet from "../../assets/images/tabletimg/epoxytablet.png";
import timbertablet from "../../assets/images/tabletimg/timbertablet.png";



function Index() {

  const [showModal, setShowModal] = React.useState(false);

  // Function to handle modal show
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleContent = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const borderDiv = document.querySelector(".border-div2");
      const translateX = scrollPosition * 0.3 + "px"; // Adjust the multiplier as needed
      borderDiv.style.transform = "translateX(" + translateX + ")";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const borderDiv = document.querySelector(".border-div3");
      const translateX = scrollPosition * 0.3 + "px"; // Adjust the multiplier as needed
      borderDiv.style.transform = "translateX(" + translateX + ")";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header />

      <div class="side-space home-pg">
        <section class="sec-1">
          <div class="bg-video-wrap">
            <video src={video4} loop muted autoPlay></video>
            <div class="overlay"></div>

            <div class="banner-content">
              <h1 class="">SOLIDIFICATION SOLUTIONS </h1>
              <p id="content" className="showdesktab">
                Welcome to Solidification Solutions, your premier destination
                for exceptional flooring solutions in the flooring construction
                industry. We specialize in the art of transforming concrete,
                timber and epoxy floors into stunning, polished masterpieces
                that enhance the aesthetic appeal and functionality of any
                space. <br />
                <span
                  id="more"
                  style={{ display: showMore ? "inline" : "none" }}
                >
                  At Solidification Solutions, we take pride in our expertise in
                  concrete, timber and epoxy flooring industry, offering a range
                  of services that include but not limited to polishing,
                  grinding, and epoxy applications. Our skilled craftsmen are
                  equipped with the latest tools and techniques to deliver
                  flawless results, whether it's a residential, commercial, or
                  industrial project.
                  <br />
                  What sets us apart is our unwavering commitment to quality. We
                  meticulously assess each flooring project to develop a
                  tailored approach that ensures optimal results, meeting and
                  exceeding our clients' expectations. With a keen eye for
                  detail and a passion for perfection, we transform worn-out,
                  dull floors into sleek, durable surfaces that radiate elegance
                  and durability.
                  <br />
                  Whether you're looking to revitalize your home, upgrade your
                  office space, or enhance the ambiance of your retail store,
                  our flooring solutions are designed to make a lasting
                  impression. We work closely with our clients, understanding
                  their vision and requirements, to deliver personalized
                  flooring solutions that reflect their unique style and
                  preferences.
                  <br />
                  In addition to our concrete and timber floor services, we
                  offer a wide range of finishes and coatings, including epoxy
                  applications. This not only enhances the visual appeal of your
                  floors but also improves their resistance to stains,
                  chemicals, and wear-and-tear, ensuring long-lasting
                  durability. At Solidification Solutions, we prioritize
                  customer satisfaction above all else. From our initial
                  consultation to the final walk-through, we strive to provide a
                  seamless and enjoyable experience. Our team is known for its
                  professionalism, punctuality, and attention to detail,
                  ensuring that every project is completed on time and within
                  budget.
                  <br />
                  Ready to transform your floors into works of art? Contact
                  Solidification Solutions today and let our flooring experts
                  bring your vision to life. Discover the beauty, durability,
                  and versatility of polished concrete and timber flooring with
                  us.
                </span>
                {!showMore && (
                  <a href="#" onClick={toggleContent}>
                    See More
                  </a>
                )}
                {showMore && (
                  <a href="#" onClick={toggleContent}>
                    See Less
                  </a>
                )}
              </p>

{/* show mobile */}
              <p id="content" className="onlymobileshow">
                Welcome to Solidification Solutions, your premier destination
                for exceptional flooring solutions in the flooring construction
                industry.                      <a onClick={handleShowModal} className="see-about">
                      See more
                    </a>
                    <Modal
                      className="ab-modal"
                      show={showModal}
                      onHide={handleCloseModal}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div class="modal-body">
                          <div class="inner-title">
                        

                            <p>
                              Welcome to Solidification Solutions, your premier
                              destination for all your concrete and timber
                              flooring needs. With a passion for craftsmanship
                              and a commitment to excellence, we specialize in
                              providing top-quality concrete overlays, concrete
                              grinding and polishing, epoxy coatings, and timber
                              sanding and polishing services. <br />
                              <br />
                              At Solidification Solutions, we understand that
                              your floors are a vital aspect of your space,
                              adding both aesthetic appeal and functionality.
                              That's why we pride ourselves on delivering
                              exceptional results that transform ordinary floors
                              into extraordinary works of art.
                              <br />
                              <br />
                              With years of industry experience, our team of
                              skilled professionals possesses the expertise and
                              knowledge required to tackle projects of any size
                              and complexity. Whether you're looking to enhance
                              your residential or commercial space, we have the
                              expertise to turn your vision into reality.
                              <br />
                              <br />
                              Our concrete overlays offer a versatile and
                              cost-effective solution to revitalize your
                              existing concrete surfaces. From driveways and
                              patios to interior floors, our overlays are
                              designed to provide a durable and visually
                              stunning finish that surpasses your expectations.
                              <br />
                              <br />
                              If your concrete floors are in need of restoration
                              or enhancement, our concrete grinding and
                              polishing services are tailored to meet your
                              unique requirements. Using state-of-the-art
                              equipment and techniques, we carefully remove
                              imperfections, smooth out surfaces, and apply
                              high-quality finishes to create floors that are
                              both durable and visually appealing.
                              <br />
                              <br />
                              In addition to concrete services, we also
                              specialize in epoxy coatings. Our epoxy coatings
                              are a fantastic way to protect your floors while
                              adding a touch of elegance. Whether you need an
                              epoxy coating for your garage, warehouse, or
                              commercial space, we offer a wide range of colours
                              and finishes to suit your style and functional
                              needs.
                              <br />
                              <br />
                              Furthermore, we are experts in timber sanding and
                              polishing. We understand the timeless beauty and
                              warmth that timber floors bring to a space. Our
                              meticulous sanding and polishing techniques
                              breathe new life into worn-out timber, restoring
                              its natural splendour and enhancing the overall
                              ambiance of your space.
                              <br />
                              <br />
                              At Solidification Solutions, we prioritize
                              customer satisfaction above all else. We strive to
                              provide personalized service, working closely with
                              you throughout the entire process to ensure that
                              your specific needs and desires are met. We take
                              pride in our attention to detail, superior
                              craftsmanship, and commitment to delivering
                              exceptional results on time and within budget.
                              <br />
                              <br />
                              When you choose Solidification Solutions, you're
                              choosing a team dedicated to quality,
                              professionalism, and customer satisfaction. Let us
                              transform your concrete and timber floors into
                              stunning masterpieces that will leave a lasting
                              impression. Contact us today for a consultation
                              and let us bring your flooring dreams to life!
                            </p>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </p>
                  
{/* show mobile */}
            </div>




          </div>
        </section>

        <div class="side-padding">
          <section class="sec-3 about-sec1 home-sec">
            <div class="inner-title pb-5">
              <h1
                data-aos="fade-up"
                data-aos-duration="1600"
                class="aos-init aos-animate"
              >
                <span></span>CONCRETE Solutions
              </h1>
            </div>
                {/* Desktop */}
            <div class="row table-none">
              <div class="col-lg-5 epoxy-col">
                <div class="concert-content up">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                   CONCRETE OVERLAY 
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    PLAIN CONCRETE <br />
                    Stipple Finish | Smooth Finish | <br /> Burnt Finish
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    COLOURED CONCRETE
                    <br />
                    Stipple Finish | Smooth Finish
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    STENCIL PATTERNS
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    EXPOSED AGGREGATE
                  </p>
         
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                            STAMPED PATTERNS
                  </p>
                  <div class="solid-btn">
                    <Link to="/ConcretOverlay">Read More</Link>
                  </div>
                </div>

                <div class="concert-content added-new">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    Concrete crack and repair
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    Crack injection
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    Concrete stitches
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    Patching and repairs
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    Reinforcement protection and preservation
                  </p>
                  <div class="solid-btn">
                    <Link to="/Concrete">Read More</Link>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 home-con">
                <div class="concert-img">
                  <img src={homefirstimg} />
                </div>
                <div class="concert-content ">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    CONCRETE GRIND & POLISH
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    RECTIFICATIONS / SURFACE <br />
                    PREPARATION
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    CONCRETE GRINDING &
                    <br /> SEALING
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    CONCRETE POLISHING
                    <br />
                    Satin | Matt | Gloss | High Gloss
                  </p>

                  <div class="solid-btn">
                    <Link to="/ConcreteGranding">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
    {/* Desktop end*/}


            {/* tablet */}
            <div class="row destop-none" >
            <div class="col-lg-7 ">
                <div class="concert-tablet">
                  <img src={ConcreteOverlay} />
                </div>
              </div>
              <div class="col-lg-5 epoxy-col" style={{ marginBottom:"20px"}}>
              <div class="concert-content ">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                   CONCRETE OVERLAY 
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    PLAIN CONCRETE <br />
                    Stipple Finish | Smooth Finish | <br /> Burnt Finish
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    COLOURED CONCRETE
                    <br />
                    Stipple Finish | Smooth Finish
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    STENCIL PATTERNS
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    EXPOSED AGGREGATE
                  </p>
         
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                            STAMPED PATTERNS
                  </p>
                  <div class="solid-btn">
                    <Link to="/ConcretOverlay">Read More</Link>
                  </div>
                </div>
              </div>
       
         
           
              <div class="col-lg-7 ">
                <div class="concert-tablet">
                  <img src={GRINDimg} />
                </div>
              </div>
              <div class="col-lg-5 epoxy-col"  style={{ marginBottom:"20px"}}>
                <div class="concert-content ">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    CONCRETE GRIND & POLISH
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    RECTIFICATIONS / SURFACE <br />
                    PREPARATION
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    CONCRETE GRINDING &
                    <br /> SEALING
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    CONCRETE POLISHING
                    <br />
                    Satin | Matt | Gloss | High Gloss
                  </p>

                  <div class="solid-btn">
                    <Link to="/ConcreteGranding">Read More</Link>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 ">
                <div class="concert-tablet">
                  <img src={Crackimg} />
                </div>
              </div>
              <div class="col-lg-5 epoxy-col" style={{ marginBottom:"20px"}}>
                <div class="concert-content ">
                  <h1
                    class="home-title aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    Concrete crack and repair
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    class="aos-init aos-animate"
                  >
                    Crack injection
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1700"
                    class="aos-init aos-animate"
                  >
                    Concrete stitches
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    Patching and repairs
                  </p>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="1800"
                    class="aos-init aos-animate"
                  >
                    Reinforcement protection and preservation
                  </p>
                  <div class="solid-btn">
                    <Link to="/Concrete">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
 {/* tablet end*/}
          </section>



 {/* tablet */}
          <section class="sec-3 destop-none">
            <div class="row">
              <div class="col-lg-7">
                <div class="concert-tablet">
                  <div style={{}}>
                    <img src={epoxytablet} />
                    {/* <img src={Epoxyfactory2} /> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-5 epoxy-col">
                <div class="concert-content" style={{ left: "20px" }}>
                  <h1
                    class="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    EPOXY <br />
                    Solutions
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1600">
                    RECTIFICATIONS / SURFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    SLIP RESISTANT COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    DECORATIVE FLAKE | STONE LOOK FLAKE
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1900">
                    COLOURED COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    COVINGS
                  </p>

                  <div class="solid-btn">
                    <Link to="/Epoxy">Read More</Link>
                  </div>
                </div>

                <img src={Epoxyfactory2} className="table-none"/>
              </div>
              {/* <div class="border-div3"></div> */}

          
            </div>
          </section>
 {/* tablet end*/}

    {/* Desktop */}
          <section class="sec-3 hm-com table-none">
            <div class="row">
              <div class="col-lg-7">
                <div class="concert-img">
                  <div style={{}}>
                    <img src={Epoxyfactory} />
                    {/* <img src={Epoxyfactory2} /> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-5 epoxy-col">
                <div class="concert-content" style={{ left: "50px" }}>
                  <h1
                    class="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    EPOXY <br />
                    Solutions
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1600">
                    RECTIFICATIONS / SURFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    SLIP RESISTANT COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    DECORATIVE FLAKE | STONE LOOK FLAKE
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1900">
                    COLOURED COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    COVINGS
                  </p>

                  <div class="solid-btn">
                    <Link to="/Epoxy">Read More</Link>
                  </div>
                </div>

                <img src={Epoxyfactory2} className="table-none"/>
              </div>
              <div class="border-div3"></div>

              {/* <!-- <div class="line"></div> --> */}
            </div>
          </section>
    {/* Desktop end*/}

           {/* tablet */}
           <section class="sec-4 destop-none">
            <div class="row" style={{ marginBottom: "80px" }}>
            <div class="col-lg-7">
                <div class="concert-tablet">
                  <img src={timbertablet} />
                
                  {/* <div class="border-div2 "></div> */}
                </div>
              </div>
              <div class="col-lg-5">
                <div class="concert-content">
                  <h1
                    class="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    TIMBER floor services
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1600">
                    RECTIFICATIONS / SURFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    TIMBER FLOOR SANDING
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    TIMBER FLOOR COATINGS <br />
                    MATT | SATIN | GLOSS
                  </p>

                  <div class="solid-btn">
                    <Link to="/Timber">Read More</Link>
                  </div>
                </div>
              </div>
             
            </div>
          </section>
 {/* tablet end*/}


   {/* Desktop  */}
          <section class="sec-4 table-none">
            <div class="row" style={{ marginBottom: "-80px" }}>
              <div class="col-lg-5">
                <div class="concert-content">
                  <h1
                    class="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    TIMBER floor services
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1600">
                    RECTIFICATIONS / SURFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    TIMBER FLOOR SANDING
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    TIMBER FLOOR COATINGS <br />
                    MATT | SATIN | GLOSS
                  </p>

                  <div class="solid-btn">
                    <Link to="/Timber">Read More</Link>
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="concert-img">
                  <img src={Timber} />
                  <img src={Timber2} />
                  <div class="border-div2 "></div>
                </div>
              </div>
            </div>
          </section>
           {/* Desktop end*/}

        </div>
      </div>

      <Footer />
    </>
  );
}

export default memo(Index);
