import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import React, { memo, useEffect } from 'react';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import epoxy1 from "../../assets/video/epoxy1.mp4"
import epoxyimg1 from "../../assets/images/epoxy-img1.png"
import gr1 from "../../assets/images/gr-1.png"
import epicon1 from "../../assets/images/ep-icon1.png"
import epicon2 from "../../assets/images/ep-icon2.png"
import epicon3 from "../../assets/images/ep-icon3.png"
import Aesthetics from "../../assets/images/Aesthetics.png"
import ad1 from "../../assets/images/ad-1.png"
import ad2 from "../../assets/images/ad-2.png"
import ad3 from "../../assets/images/ad-3.png"
import ad4 from "../../assets/images/ad-4.png"

function Epoxy() {



    useEffect(() => {
        AOS.init({
          // AOS configuration options
        });
    
        // Refresh AOS when component mounts
        AOS.refresh();
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const handleScroll = () => {
        // Refresh AOS on scroll to trigger animations
        AOS.refresh();
      };

      
  return (
    
    <div>
   <Header/>
   <div class="side-space">

<section class="sec-1 concrete-sec-1">
    <div class="bg-video-wrap">
    <video src={epoxy1} loop muted autoPlay>
            </video>
        <div class="overlay">
        </div>

        <div class="banner-content">
            <h1 class="">EPOXY</h1>




        </div>


    </div>

</section>


<div class="side-padding">


    <section class="sec-2-concret">

        <div class="inner-title">
            <h1 data-aos="fade-up" data-aos-duration="1800"><span></span>EPOXY COATINGS</h1>
            <p data-aos="fade-up" data-aos-duration="2000">Epoxy flooring is a popular choice for various commercial and industrial <br/> applications due to its
                numerous advantages</p>

        </div>
        <div class="grind">
            <img src={epoxyimg1} />
        </div>
        <p class="oneline-para" data-aos="fade-up" data-aos-duration="1800">Here are some of the advantages and disadvantages of epoxy flooring</p>
        <div class="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span>Advantages OF EPOXY FLOORING</h1>


        </div>
        <div class="row mt-5">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={gr1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800"> Durability</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy flooring is highly durable and can withstand heavy 
foot traffic, impacts, and abrasions. It is resistant to 
chemicals, stains, and moisture, making it suitable 
for industrial environments.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={epicon1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Strength</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy flooring provides a strong and rigid surface
                        that can handle the weight of machinery, vehicles,
                        and equipment. It is often used in garages,
                        warehouses, and manufacturing facilities.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={epicon2}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Easy Maintenance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy floors are relatively easy to clean and maintain.
                        They have a seamless surface that prevents the
                        accumulation of dirt, dust, and debris. Regular
                        sweeping and occasional mopping are usually
                        sufficient for maintenance.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={Aesthetics}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Aesthetic Appeal</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy flooring offers a sleek, professional look that
                        can enhance the overall appearance of a space.
                        It is available in various colours, finishes, and
                        patterns, allowing for customization to
                        match specific design preferences.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={epicon3}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Chemical Resistance</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Concrete grinding and sealing offer versatility in design
                        options. The process can be customized to achieve different
                        levels of aggregate exposure, from a light sand-like finish
                        to a high-gloss polished appearance. This allows for various
                        decorative possibilities and the ability to match the concrete
                        to the overall design scheme of the space.</p>
                </div>
            </div>



            <p class="oneline-para" data-aos="fade-up" data-aos-duration="1800">However there are some disadvantages of epoxy flooring to consider.</p>
        </div>
    </section>



    <section class="sec-2-concret s-4">
        <div class="inner-title" data-aos="fade-up" data-aos-duration="1800">
            <h1><span></span> DISAdvantages OF EPOXY FLOORING</h1>


        </div>

        <div class="row mt-5">
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={ad1} data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Initial Cost</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy flooring can be more expensive to install compared to
                        other flooring options. The cost includes surface preparation,
                        application, and curing time. However, its long-term
                        durability and low maintenance requirements can
                        offset the initial investment.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={ad2}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Slippery When Wet</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Epoxy floors can be slippery when they are wet
                        or exposed to certain contaminants. To mitigate
                        this issue, additives such as non-slip aggregates
                        can be incorporated into the epoxy coating to
                        improve traction.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={ad3}  data-aos="fade-up" data-aos-duration="1600"/>
                    <h2 data-aos="fade-up" data-aos-duration="1800">Limited Repair Options</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Once an epoxy floor is damaged or develops cracks, repairing
                        it can be challenging. In some cases, the entire flooring may
                        need to be replaced. Therefore, it is important to take
                        preventive measures to minimize potential damage.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="Grand-content">
                    <img src={ad4}  data-aos="fade-up" data-aos-duration="1800"/>
                    <h2 data-aos="fade-up" data-aos-duration="1600">Time and Skill Requirements</h2>
                    <p data-aos="fade-up" data-aos-duration="2000">Proper installation of epoxy flooring requires specialized
                        knowledge, skills, and equipment. It is recommended to
                        hire professional installers to ensure a high-quality
                        and long-lasting result.</p>
                </div>
            </div>




            <div class="single-para" data-aos="fade-up" data-aos-duration="1800">
                <p>Overall, epoxy flooring offers many advantages in terms of durability, strength, easy
                    maintenance, and aesthetic appeal. However, it is essential to consider the specific needs of
                    your space, the potential risks associated with slipperiness, and the initial cost before
                    choosing epoxy flooring as the ideal option for your application.
                </p>
            </div>
        </div>
    </section>

    <div class="furter-inquiry" >
        <p data-aos="fade-up" data-aos-duration="1600">For further insight and tailored specific projects.</p>
        <h5 data-aos="fade-up" data-aos-duration="1800">Give us a call or email </h5>
        <p data-aos="fade-up" data-aos-duration="2000"> <a href="maito:michael@solidificationsolutions.com.au">michael@solidificationsolutions.com.au</a> </p>
    </div>

</div>

</div>
<Footer/>
    </div>
  )
}


export default memo(Epoxy) 