import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import React, { memo, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import video4 from "../../assets/video/home-banner.mp4";
import aboutimg1 from "../../assets/images/about-img1.png";
import concrethome from "../../assets/images/concrethome.png";
import Epoxyabout from "../../assets/images/Epoxy-about.png";
import Timberabout from "../../assets/images/Timber-about.png";
import client1 from "../../assets/images/client1.png";
import clientleft from "../../assets/images/client-left.png";
import clientright from "../../assets/images/client-right.png";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

import ConcreteOverlay from "../../assets/images/tabletimg/ConcreteOverlay.png";
import Crackimg from "../../assets/images/tabletimg/Crackimg.png";
import GRINDimg from "../../assets/images/tabletimg/GRINDimg.png";
import epoxytablet from "../../assets/images/tabletimg/epoxytablet.png";
import timbertablet from "../../assets/images/tabletimg/timbertablet.png";

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none" }}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function About() {
  const [showModal, setShowModal] = React.useState(false);

  // Function to handle modal show
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  var settings2 = {
    centerMode: true,
    centerPadding: "0",
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    Speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  useEffect(() => {
    AOS.init({
      // AOS configuration options
    });

    // Refresh AOS when component mounts
    AOS.refresh();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Refresh AOS on scroll to trigger animations
    AOS.refresh();
  };

  //   useEffect(() => {
  //     const handleScroll = () => {
  //         const scrolled = window.pageYOffset;
  //         const parallaxImage = document.querySelector('.parallax-image');
  //         parallaxImage.style.transform = `translate3d(0px, ${-scrolled * .03}px, 0px)`;
  //       };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const borderDiv = document.querySelector(".border-div");
      const translateX = scrollPosition * 0.3 + "px"; // Adjust the multiplier as needed
      borderDiv.style.transform = "translateX(" + translateX + ")";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Header />
      <div class="side-space about-page">
        <section class="sec-1 concrete-sec-1">
          <div class="bg-video-wrap">
            <video src={video4} loop muted autoPlay></video>
            <div class="overlay"></div>

            <div class="banner-content">
              <h1 class="">About Us</h1>
            </div>
          </div>
        </section>

        <div className="side-padding">
          <section className="sec-3 about-sec1 tb-mb">
            <div className="row">
              {/* <div className="col-lg-5 epoxy-col">
                    <div className="concert-content">
                        <h1 className="home-title"   data-aos-duration="1800">About Us</h1>
                        <p data-aos="fade-up" data-aos-duration="2000">Welcome to Solidification Solutions, your premier
                            destination for all your concrete and timber flooring needs. With a passion for
                            craftsmanship and a commitment to excellence, we specialize in providing top-quality
                            concrete overlays, concrete grinding and polishing, epoxy coatings, and timber sanding and
                            polishing services.
                            <a data-bs-toggle="modal" data-bs-target="#exampleModal2" className="see-about">See more</a>
                        </p>
                    </div>
                </div> */}
              <div className="col-lg-5 epoxy-col">
                <div className="concert-content table-none">
                  <h1 className="home-title" data-aos-duration="1800">
                    About Us
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Welcome to Solidification Solutions, your premier
                    destination for all your concrete and timber flooring needs.
                    With a passion for craftsmanship and a commitment to
                    excellence, we specialize in providing top-quality concrete
                    overlays, concrete grinding and polishing, epoxy coatings,
                    and timber sanding and polishing services.
                    <a onClick={handleShowModal} className="see-about">
                      See more
                    </a>
                    <Modal
                      className="ab-modal"
                      show={showModal}
                      onHide={handleCloseModal}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div class="modal-body">
                          <div class="inner-title">
                            <h1>
                              <span></span>About Us
                            </h1>

                            <p>
                              Welcome to Solidification Solutions, your premier
                              destination for all your concrete and timber
                              flooring needs. With a passion for craftsmanship
                              and a commitment to excellence, we specialize in
                              providing top-quality concrete overlays, concrete
                              grinding and polishing, epoxy coatings, and timber
                              sanding and polishing services. <br />
                              <br />
                              At Solidification Solutions, we understand that
                              your floors are a vital aspect of your space,
                              adding both aesthetic appeal and functionality.
                              That's why we pride ourselves on delivering
                              exceptional results that transform ordinary floors
                              into extraordinary works of art.
                              <br />
                              <br />
                              With years of industry experience, our team of
                              skilled professionals possesses the expertise and
                              knowledge required to tackle projects of any size
                              and complexity. Whether you're looking to enhance
                              your residential or commercial space, we have the
                              expertise to turn your vision into reality.
                              <br />
                              <br />
                              Our concrete overlays offer a versatile and
                              cost-effective solution to revitalize your
                              existing concrete surfaces. From driveways and
                              patios to interior floors, our overlays are
                              designed to provide a durable and visually
                              stunning finish that surpasses your expectations.
                              <br />
                              <br />
                              If your concrete floors are in need of restoration
                              or enhancement, our concrete grinding and
                              polishing services are tailored to meet your
                              unique requirements. Using state-of-the-art
                              equipment and techniques, we carefully remove
                              imperfections, smooth out surfaces, and apply
                              high-quality finishes to create floors that are
                              both durable and visually appealing.
                              <br />
                              <br />
                              In addition to concrete services, we also
                              specialize in epoxy coatings. Our epoxy coatings
                              are a fantastic way to protect your floors while
                              adding a touch of elegance. Whether you need an
                              epoxy coating for your garage, warehouse, or
                              commercial space, we offer a wide range of colours
                              and finishes to suit your style and functional
                              needs.
                              <br />
                              <br />
                              Furthermore, we are experts in timber sanding and
                              polishing. We understand the timeless beauty and
                              warmth that timber floors bring to a space. Our
                              meticulous sanding and polishing techniques
                              breathe new life into worn-out timber, restoring
                              its natural splendour and enhancing the overall
                              ambiance of your space.
                              <br />
                              <br />
                              At Solidification Solutions, we prioritize
                              customer satisfaction above all else. We strive to
                              provide personalized service, working closely with
                              you throughout the entire process to ensure that
                              your specific needs and desires are met. We take
                              pride in our attention to detail, superior
                              craftsmanship, and commitment to delivering
                              exceptional results on time and within budget.
                              <br />
                              <br />
                              When you choose Solidification Solutions, you're
                              choosing a team dedicated to quality,
                              professionalism, and customer satisfaction. Let us
                              transform your concrete and timber floors into
                              stunning masterpieces that will leave a lasting
                              impression. Contact us today for a consultation
                              and let us bring your flooring dreams to life!
                            </p>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </p>
                </div>

                <div className="concert-content destop-none pb-3">
                  <div class="inner-title pb-2">
                    <h1
                      data-aos="fade-up"
                      data-aos-duration="1600"
                      class="aos-init aos-animate"
                    >
                      <span></span>About Us
                    </h1>
                  </div>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    Welcome to Solidification Solutions, your premier
                    destination for all your concrete and timber flooring needs.
                    With a passion for craftsmanship and a commitment to
                    excellence, we specialize in providing top-quality concrete
                    overlays, concrete grinding and polishing, epoxy coatings,
                    and timber sanding and polishing services.
                    <a onClick={handleShowModal} className="see-about">
                      See more
                    </a>
                    <Modal
                      className="ab-modal"
                      show={showModal}
                      onHide={handleCloseModal}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div class="modal-body">
                          <div class="inner-title">
                            <h1>
                              <span></span>About Us
                            </h1>

                            <p>
                              Welcome to Solidification Solutions, your premier
                              destination for all your concrete and timber
                              flooring needs. With a passion for craftsmanship
                              and a commitment to excellence, we specialize in
                              providing top-quality concrete overlays, concrete
                              grinding and polishing, epoxy coatings, and timber
                              sanding and polishing services. <br />
                              <br />
                              At Solidification Solutions, we understand that
                              your floors are a vital aspect of your space,
                              adding both aesthetic appeal and functionality.
                              That's why we pride ourselves on delivering
                              exceptional results that transform ordinary floors
                              into extraordinary works of art.
                              <br />
                              <br />
                              With years of industry experience, our team of
                              skilled professionals possesses the expertise and
                              knowledge required to tackle projects of any size
                              and complexity. Whether you're looking to enhance
                              your residential or commercial space, we have the
                              expertise to turn your vision into reality.
                              <br />
                              <br />
                              Our concrete overlays offer a versatile and
                              cost-effective solution to revitalize your
                              existing concrete surfaces. From driveways and
                              patios to interior floors, our overlays are
                              designed to provide a durable and visually
                              stunning finish that surpasses your expectations.
                              <br />
                              <br />
                              If your concrete floors are in need of restoration
                              or enhancement, our concrete grinding and
                              polishing services are tailored to meet your
                              unique requirements. Using state-of-the-art
                              equipment and techniques, we carefully remove
                              imperfections, smooth out surfaces, and apply
                              high-quality finishes to create floors that are
                              both durable and visually appealing.
                              <br />
                              <br />
                              In addition to concrete services, we also
                              specialize in epoxy coatings. Our epoxy coatings
                              are a fantastic way to protect your floors while
                              adding a touch of elegance. Whether you need an
                              epoxy coating for your garage, warehouse, or
                              commercial space, we offer a wide range of colours
                              and finishes to suit your style and functional
                              needs.
                              <br />
                              <br />
                              Furthermore, we are experts in timber sanding and
                              polishing. We understand the timeless beauty and
                              warmth that timber floors bring to a space. Our
                              meticulous sanding and polishing techniques
                              breathe new life into worn-out timber, restoring
                              its natural splendour and enhancing the overall
                              ambiance of your space.
                              <br />
                              <br />
                              At Solidification Solutions, we prioritize
                              customer satisfaction above all else. We strive to
                              provide personalized service, working closely with
                              you throughout the entire process to ensure that
                              your specific needs and desires are met. We take
                              pride in our attention to detail, superior
                              craftsmanship, and commitment to delivering
                              exceptional results on time and within budget.
                              <br />
                              <br />
                              When you choose Solidification Solutions, you're
                              choosing a team dedicated to quality,
                              professionalism, and customer satisfaction. Let us
                              transform your concrete and timber floors into
                              stunning masterpieces that will leave a lasting
                              impression. Contact us today for a consultation
                              and let us bring your flooring dreams to life!
                            </p>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </p>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="concert-img">
                  <img src={aboutimg1} />
                </div>
              </div>
              <div className="border-div"></div>
            </div>
          </section>

          <section className="sec-4 ">
            <div className="inner-title pb-5">
              <h1 data-aos="fade-up" data-aos-duration="1600">
                <span></span>OUR SERVICES
              </h1>
            </div>

            <section className="sec-3 about-sec1 home-sec">
              <div className="inner-title pb-5">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  className="aos-init aos-animate"
                >
                  <span></span>CONCRETE Solutions
                </h1>
              </div>
              {/* Desktop */}
              <div className="row table-none">
                <div className="col-lg-5 epoxy-col">
                  <div className="concert-content">
                    <h1
                      className="home-title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      CONCRETE OVERLAY
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1600"
                      className="aos-init aos-animate"
                    >
                      PLAIN CONCRETE
                      <br />
                      Stipple Finish | Smooth Finish | Burnt Finish
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1700"
                      className="aos-init aos-animate"
                    >
                      COLOURED CONCRETE
                      <br />
                      Stipple Finish | Smooth Finish
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      STENCIL PATTERNS
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      EXPOSED AGGREGATE
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      STAMPED PATTERNS
                    </p>
                    <div className="solid-btn">
                      <Link to="/ConcretOverlay">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 home-con">
                  <div className="concert-img">
                    <img src={concrethome} />
                  </div>
                  <div className="concert-content ">
                    <h1
                      className="home-title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      CONCRETE GRIND &amp; POLISH
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1600"
                      className="aos-init aos-animate"
                    >
                      RECTIFICATIONS / SURFACE <br />
                      PREPARATION
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1700"
                      className="aos-init aos-animate"
                    >
                      CONCRETE GRINDING &amp;
                      <br /> SEALING
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      CONCRETE POLISHING
                      <br />
                      Satin | Matt | Gloss | High Gloss
                    </p>

                    <div className="solid-btn">
                      <Link to="/Concrete">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* Desktop end*/}
              {/* tablet */}
              <div class="row destop-none">
                <div class="col-lg-7 ">
                  <div class="concert-tablet">
                    <img src={ConcreteOverlay} />
                  </div>
                </div>
                <div class="col-lg-5 epoxy-col">
                  <div class="concert-content">
                    <h1
                      className="home-title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      CONCRETE OVERLAY
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1600"
                      className="aos-init aos-animate"
                    >
                      PLAIN CONCRETE
                      <br />
                      Stipple Finish | Smooth Finish | Burnt Finish
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1700"
                      className="aos-init aos-animate"
                    >
                      COLOURED CONCRETE
                      <br />
                      Stipple Finish | Smooth Finish
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      STENCIL PATTERNS
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      EXPOSED AGGREGATE
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      className="aos-init aos-animate"
                    >
                      STAMPED PATTERNS
                    </p>
                    <div className="solid-btn">
                      <Link to="/ConcretOverlay">Read More</Link>
                    </div>
                  </div>
                </div>

                <div class="col-lg-7 ">
                  <div class="concert-tablet">
                    <img src={concrethome} />
                  </div>
                </div>
                <div class="col-lg-5 epoxy-col">
                  <div class="concert-content ">
                    <h1
                      class="home-title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      CONCRETE GRIND & POLISH
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1600"
                      class="aos-init aos-animate"
                    >
                      RECTIFICATIONS / SURFACE <br />
                      PREPARATION
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1700"
                      class="aos-init aos-animate"
                    >
                      CONCRETE GRINDING &
                      <br /> SEALING
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1800"
                      class="aos-init aos-animate"
                    >
                      CONCRETE POLISHING
                      <br />
                      Satin | Matt | Gloss | High Gloss
                    </p>

                    <div class="solid-btn">
                      <Link to="/ConcreteGranding">Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* tablet end*/}
            </section>
          </section>

          {/* Desktop */}
          <section className="sec-3 table-none">
            <div className="row">
              <div className="col-lg-7">
                <div className="concert-img">
                  <div className="">
                    <img src={Epoxyabout} className="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 epoxy-col m-auto">
                <div className="concert-content" style={{ left: "50px" }}>
                  <h1
                    className="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  >
                    EPOXY Solutions
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    RECTIFICATIONS / SURPHACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    SLIP RESISTANT COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1900">
                    DECORATIVE FLAKE | STONE LOOK FLAKE
                  </p>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    COLOURED COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="2100">
                    COVINGS
                  </p>

                  <div
                    className="solid-btn"
                    data-aos="fade-up"
                    data-aos-duration="2100"
                  >
                    <Link to="/Epoxy">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Desktop end*/}

          {/* tablet */}
          <section class="sec-3 destop-none">
            <div class="row">
              <div class="col-lg-7">
                <div class="concert-tablet">
                  <div style={{}}>
                    <img src={epoxytablet} />
                    {/* <img src={Epoxyfactory2} /> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-5 epoxy-col">
                <div class="concert-content" style={{ left: "50px" }}>
                  <h1
                    class="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    EPOXY <br />
                    Solutions
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1600">
                    RECTIFICATIONS / SURFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    SLIP RESISTANT COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    DECORATIVE FLAKE | STONE LOOK FLAKE
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1900">
                    COLOURED COATINGS
                  </p>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    COVINGS
                  </p>

                  <div class="solid-btn">
                    <Link to="/Epoxy">Read More</Link>
                  </div>
                </div>

                {/* <img src={Epoxyfactory2} className="table-none"/> */}
              </div>
              {/* <div class="border-div3"></div> */}
            </div>
          </section>
          {/* tablet end*/}

          <section className="sec-4 tablet-sec-4">
            <div className="row">
              <div className="col-lg-5 m-auto">
                <div className="concert-content">
                  <h1
                    className="home-title"
                    data-aos="fade-up"
                    data-aos-duration="1600"
                  >
                    TIMBER floor SOLUTIONS
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1700">
                    RECTIFICATIONS / SURPFACE PREPARATION
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1800">
                    TIMBER FLOOR SANDING
                  </p>
                  <p data-aos="fade-up" data-aos-duration="1900">
                    TIMBER FLOOR COATINGS <br />
                    MATT | SATIN | GLOSS
                  </p>

                  <div
                    className="solid-btn"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Link to="/Timber">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="concert-img">
                  <img src={Timberabout} />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="parent-testimonial">
              <div
                className="inner-title pb-5"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>
                  <span></span>TESTIMONIALS
                </h1>
              </div>
              <div className="testimonial ">
                <span className="client-left">
                  <img src={clientleft} />
                </span>
                <span className="client-right">
                  <img src={clientright} />
                </span>
                <div className="ad-test">
                  <Slider {...settings2}>
                    <div className="test-content">
                      <div className="test-img">
                        <img src={client1} />
                      </div>
                      <div>
                        <span>"</span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur sed do eiusmod
                          tempor incididunt ut labore et magna aliqua. Ut enim
                          ad minim veniam.
                        </p>
                        <h6>Laura Wilson</h6>
                        <p>Director Marketing</p>
                      </div>
                    </div>
                    <div className="test-content">
                      <div class="test-img">
                        <img src={client1} />
                      </div>
                      <div>
                        <span>"</span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur sed do eiusmod
                          tempor incididunt ut labore et magna aliqua. Ut enim
                          ad minim veniam.
                        </p>
                        <h6>Laura Wilson</h6>
                        <p>Director Marketing</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default memo(About);
